import React from "react";
import { PinInput } from "react-input-pin-code";
import ErrorForm from "../form/Error";

export default function OtpForm({
  loading,
  error,
  code,
  handleCode,
  codeValidator
}) {

  return (
    <>
      <div className="mt-8 text-white">
        <PinInput
          borderColor="rgb(255,255,255)"
          focusBorderColor="rgb(255,255,255)"
          placeholder="-"
          values={code}
          type="text"
          onChange={(value, index, values) => handleCode(values)}
        />
        {error?.length !== 0 && (
            <ErrorForm message={error} />
        )}
        <button
            disabled={loading}
            onClick={codeValidator}
            className={`buttonForm my-8 focus:outline-none ${loading ? "bg-gray-300 hover:bg-gray-300 text-black" : "text-white bg-secondary hover:bg-green-600"}`}
        >
          {loading ? "Chargement..." : "CONTINUER"}
        </button>
      </div>
    </>
  );
}
