import { Outlet, Navigate } from "react-router-dom";
import {useSelector} from "react-redux";

const PrivateRoute = () => {
  const user = useSelector((state) => state.authSlice.userInfo);

  return user?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" />
  );
};

export default PrivateRoute;
